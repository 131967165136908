@value blue from "@depop/web-ui-kit/theme/default/colors.module.css";

.progressBar {
  background: blue;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
}
